import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/3pKXuKKUFHo">
    <SEO title="Faithful Facing Temptation - Living the Dream" />
  </Layout>
)

export default SermonPost
